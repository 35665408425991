// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-upskillist-gatsby-theme-course-landing-pages-src-templates-course-page-jsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-course-landing-pages/src/Templates/CoursePage.jsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-course-landing-pages-src-templates-course-page-jsx" */),
  "component---node-modules-upskillist-gatsby-theme-course-landing-pages-src-templates-partnership-page-jsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-course-landing-pages/src/Templates/PartnershipPage.jsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-course-landing-pages-src-templates-partnership-page-jsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-404-js": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/404.js" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-404-js" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-certificate-en-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/certificate/en/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-certificate-en-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-cs-agent-app-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/cs-agent/app.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-cs-agent-app-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-en-assignment-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/en/assignment/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-en-assignment-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-account-cancellation-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/learner/en/account/cancellation/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-account-cancellation-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-account-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/learner/en/account/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-account-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-add-next-module-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/learner/en/add-next-module/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-add-next-module-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-assignment-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/learner/en/assignment/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-assignment-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-certificate-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/learner/en/certificate/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-certificate-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-course-registration-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/learner/en/course-registration/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-course-registration-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-forgot-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/learner/en/forgot/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-forgot-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-hcd-request-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/learner/en/hcd-request/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-hcd-request-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/learner/en/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-login-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/learner/en/login/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-login-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-reschedule-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/learner/en/reschedule/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-reschedule-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-sales-offer-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/learner/en/sales-offer/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-sales-offer-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-terms-condition-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/learner/en/terms-condition/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-terms-condition-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-thankyou-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/learner/en/thankyou/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-thankyou-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-timetable-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/learner/en/timetable/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-timetable-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-watch-lesson-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/learner/en/watch-lesson/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-en-watch-lesson-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/learner/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-learner-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-en-assessments-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/payment/en/assessments/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-en-assessments-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-en-authorise-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/payment/en/authorise/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-en-authorise-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-en-complete-transaction-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/payment/en/complete-transaction/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-en-complete-transaction-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-en-hard-copy-diploma-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/payment/en/hard-copy-diploma/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-en-hard-copy-diploma-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-en-lifetime-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/payment/en/lifetime/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-en-lifetime-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-en-resources-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/payment/en/resources/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-en-resources-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-en-subscription-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/payment/en/subscription/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-en-subscription-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-en-subscription-trial-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/payment/en/subscription-trial/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-en-subscription-trial-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-en-trial-information-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/payment/en/trial-information/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-en-trial-information-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-en-verify-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/payment/en/verify/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-en-verify-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-v-2-en-assessments-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/payment/v2/en/assessments/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-v-2-en-assessments-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-v-2-en-complete-transaction-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/payment/v2/en/complete-transaction/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-v-2-en-complete-transaction-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-v-2-en-hard-copy-diploma-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/payment/v2/en/hard-copy-diploma/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-v-2-en-hard-copy-diploma-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-v-2-en-lifetime-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/payment/v2/en/lifetime/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-v-2-en-lifetime-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-v-2-en-purchase-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/payment/v2/en/purchase/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-v-2-en-purchase-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-v-2-en-resources-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/payment/v2/en/resources/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-v-2-en-resources-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-v-2-en-subscription-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/payment/v2/en/subscription/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-v-2-en-subscription-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-v-2-en-subscription-trial-index-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/pages/payment/v2/en/subscription-trial/index.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-pages-payment-v-2-en-subscription-trial-index-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-payments-subscription-page-lite-js": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/Payments/SubscriptionPageLite.js" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-payments-subscription-page-lite-js" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-payments-subscription-thankyou-page-js": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/Payments/SubscriptionThankyouPage.js" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-payments-subscription-thankyou-page-js" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-templates-course-catalogue-page-js": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/Templates/CourseCataloguePage.js" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-templates-course-catalogue-page-js" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-templates-course-page-js": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/Templates/CoursePage.js" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-templates-course-page-js" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-templates-faculty-page-js": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/Templates/FacultyPage.js" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-templates-faculty-page-js" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-templates-home-page-js": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/Templates/HomePage.js" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-templates-home-page-js" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-templates-learner-browse-collection-page-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/Templates/Learner/BrowseCollectionPage.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-templates-learner-browse-collection-page-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-templates-learner-course-info-page-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/Templates/Learner/CourseInfoPage.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-templates-learner-course-info-page-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-templates-learner-course-schedule-page-tsx": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/Templates/Learner/CourseSchedulePage.tsx" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-templates-learner-course-schedule-page-tsx" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-templates-signup-page-js": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/Templates/SignupPage.js" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-templates-signup-page-js" */),
  "component---node-modules-upskillist-gatsby-theme-learner-portal-src-templates-static-page-js": () => import("./../../../node_modules/@upskillist/gatsby-theme-learner-portal/src/Templates/StaticPage.js" /* webpackChunkName: "component---node-modules-upskillist-gatsby-theme-learner-portal-src-templates-static-page-js" */)
}

